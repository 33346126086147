import * as React from "react"
import { forwardRef, useRef, useState, useEffect } from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import * as styles from "./header.module.scss"

const Header = (props, ref) => {

  if ( props.location === 'oxford-st' ) {
    var logo = '/logo-oxford-st.svg'
  }

  if ( props.location === 'bns' ) {
    logo = '/logo-bns.svg'
  }

  const cover = 'url(/sd-logo.png) 50% calc(50% + (var(--header-height)/2) ) / 96px no-repeat #1C1C1C'

  const navigationRef = useRef()
  const [activePage, setActivePage] = useState()

  useEffect(() => {
    const links = navigationRef.current.getElementsByTagName('a')

    for (var i = 0; i < links.length; i++) {
      if (links[i].hasAttribute('class')) {
        if (links[i].getAttribute('class').includes('active')) {
          console.log(links[i])
          var id = links[i].getAttribute('data-id')
          setActivePage(id)
        }
      }
    }

  })

  return (

    <header ref={ref} className={styles.container}>
      <div className={styles.logo}>
        <img src={logo} alt="Sports Direct Map" />
      </div>
      <div className={styles.navigation}>
        <ul className={`${activePage === '1' ? styles.firstlink : ''} ${activePage === '2' ? styles.secondlink : ''} ${activePage === '3' ? styles.thirdlink : ''} ${activePage === '4' ? styles.fourthlink : ''} ${activePage === '5' ? styles.fifthlink : ''}`} ref={navigationRef}>
          <li>
            <AniLink cover bg={cover} direction="up" activeClassName={styles.active} partiallyActive={true} to={'/' + props.location + '/level-minus-1'} data-id="1">-1</AniLink>
          </li>
          <li>
            <AniLink cover bg={cover} direction="up" activeClassName={styles.active} to={'/' + props.location + '/'} data-id="2">0</AniLink>
          </li>
          <li>
            <AniLink cover bg={cover} direction="up" activeClassName={styles.active} partiallyActive={true} to={'/' + props.location + '/level-1'} data-id="3">1</AniLink>
          </li>
          <li>
            <AniLink cover bg={cover} direction="up" activeClassName={styles.active} partiallyActive={true} to={'/' + props.location + '/level-2'} data-id="4">2</AniLink>
          </li>
          <li>
            <AniLink cover bg={cover} direction="up" activeClassName={styles.active} partiallyActive={true} to={'/' + props.location + '/nav'} className={styles.hamburger} data-id="5">
              <div>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </AniLink>
          </li>
        </ul>
      </div>
    </header>

  )
}

export default forwardRef(Header)
