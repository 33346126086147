import * as React from "react";
import { useState, useRef, useEffect } from "react";
import { Script } from "gatsby";
import { Helmet } from "react-helmet";
import { TransitionPortal } from "gatsby-plugin-transition-link";
import Header from "../components/header";
import Background from "../components/background";
import Loader from "../components/loader";
import Notice from "../components/notice";
import MissingPage from "../components/404";
import * as styles from "./index.module.scss";

// markup
const Layout = ({ children, location }) => {
  const locationPath = location.pathname.split("/")[1];
  const fullPath = location.pathname;
  const stores = ["bns", "oxford-st"];

  function setGtmVariable(locationPath) {
    var gtm;

    if (locationPath === "bns") {
      gtm = "GTM-MGDCFNN";
    } else if (locationPath === "oxford-st") {
      gtm = "GTM-NNSTWS5";
    }

    return gtm;
  }

  const contains = stores.some((element) => {
    if (fullPath.indexOf(element) !== -1) {
      return true;
    }

    return false;
  });

  const headerRef = useRef();
  const wrapperRef = useRef();
  const [headerHeight, setHeaderHeight] = useState();
  const [firstLoad, setFirstLoad] = useState(true);
  const [windowHeight, setWindowHeight] = useState();

  if (firstLoad === true) {
    setTimeout(function () {
      setFirstLoad(false);
    }, 5000);
  }

  function updateSizes() {
    setHeaderHeight(headerRef.current.clientHeight);
    wrapperRef.current.style.paddingTop = headerHeight + "px";
    document.documentElement.style.setProperty(
      "--header-height",
      `${headerHeight}px`
    );
  }

  function appHeight() {
    setWindowHeight(window.innerHeight);
    document.documentElement.style.setProperty(
      "--app-height",
      `${windowHeight}px`
    );
  }

  useEffect(() => {
    appHeight();
    window.addEventListener("resize", appHeight);
    return (_) => {
      window.removeEventListener("resize", appHeight);
    };
  });

  useEffect(() => {
    if (contains === true) {
      updateSizes();
      window.addEventListener("resize", updateSizes);
      return (_) => {
        window.removeEventListener("resize", updateSizes);
      };
    }
  });

  if (contains === true) {
    return (
      <>
        <Script
          dangerouslySetInnerHTML={{
            __html:
              `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','` +
              setGtmVariable(locationPath) +
              `');`,
          }}
        />

        {firstLoad ? (
          <Loader location={locationPath} active={firstLoad} />
        ) : (
          <Loader location={locationPath} active={firstLoad} />
        )}
        <Notice />
        <TransitionPortal>
          <Header location={locationPath} ref={headerRef} />
        </TransitionPortal>
        <main ref={wrapperRef} className={styles.main}>
          {children}
        </main>
        <Background />
      </>
    );
  } else {
    return (
      <>
        <Helmet title="Sports Direct Digital Map" defer={false} />
        <MissingPage />
      </>
    );
  }
};

export default Layout;
