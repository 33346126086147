exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bns-index-js": () => import("./../../../src/pages/bns/index.js" /* webpackChunkName: "component---src-pages-bns-index-js" */),
  "component---src-pages-bns-level-1-js": () => import("./../../../src/pages/bns/level-1.js" /* webpackChunkName: "component---src-pages-bns-level-1-js" */),
  "component---src-pages-bns-level-2-js": () => import("./../../../src/pages/bns/level-2.js" /* webpackChunkName: "component---src-pages-bns-level-2-js" */),
  "component---src-pages-bns-level-minus-1-js": () => import("./../../../src/pages/bns/level-minus-1.js" /* webpackChunkName: "component---src-pages-bns-level-minus-1-js" */),
  "component---src-pages-bns-nav-js": () => import("./../../../src/pages/bns/nav.js" /* webpackChunkName: "component---src-pages-bns-nav-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-oxford-st-index-js": () => import("./../../../src/pages/oxford-st/index.js" /* webpackChunkName: "component---src-pages-oxford-st-index-js" */),
  "component---src-pages-oxford-st-level-1-js": () => import("./../../../src/pages/oxford-st/level-1.js" /* webpackChunkName: "component---src-pages-oxford-st-level-1-js" */),
  "component---src-pages-oxford-st-level-2-js": () => import("./../../../src/pages/oxford-st/level-2.js" /* webpackChunkName: "component---src-pages-oxford-st-level-2-js" */),
  "component---src-pages-oxford-st-level-minus-1-js": () => import("./../../../src/pages/oxford-st/level-minus-1.js" /* webpackChunkName: "component---src-pages-oxford-st-level-minus-1-js" */),
  "component---src-pages-oxford-st-nav-js": () => import("./../../../src/pages/oxford-st/nav.js" /* webpackChunkName: "component---src-pages-oxford-st-nav-js" */)
}

