// extracted by mini-css-extract-plugin
export var active = "header-module--active--5e92e";
export var container = "header-module--container--8b59b";
export var fifthlink = "header-module--fifthlink--21612";
export var firstlink = "header-module--firstlink--6004d";
export var fourthlink = "header-module--fourthlink--5ed5e";
export var hamburger = "header-module--hamburger--99d9b";
export var logo = "header-module--logo--cbdef";
export var navigation = "header-module--navigation--2fde5";
export var secondlink = "header-module--secondlink--a739b";
export var thirdlink = "header-module--thirdlink--dd773";